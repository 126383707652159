/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

/** this file is used by project to add new icons */
import dynamicHydrate from '@bluheadless/ui-logic/src/dynamic-hydration/dynamic-hydrate'
import EmptyIcon from '../icon/empty'

export * from './project-related-icons'
export const AtelierIconColored = dynamicHydrate(() => import('@/components/ui/atoms/icons/atelier-icon-colored'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const AtelierIconColoredSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/atelier-icon-colored').then((mod) => mod.AtelierIconColoredSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const Esclusione1 = dynamicHydrate(() => import('@/components/ui/atoms/icons/esclusione-1'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const Esclusione1Svg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/esclusione-1').then((mod) => mod.Esclusione1Svg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const IlGufoIconColored = dynamicHydrate(() => import('@/components/ui/atoms/icons/il-gufo-icon-colored'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const IlGufoIconColoredSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/il-gufo-icon-colored').then((mod) => mod.IlGufoIconColoredSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const ShippingIcon = dynamicHydrate(() => import('@/components/ui/atoms/icons/shipping-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const ShippingIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/shipping-icon').then((mod) => mod.ShippingIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const SustainabilityIcon = dynamicHydrate(() => import('@/components/ui/atoms/icons/sustainability-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const SustainabilityIconSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/sustainability-icon').then((mod) => mod.SustainabilityIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const TeddyAndMinouColored = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/teddy-and-minou-colored'),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const TeddyAndMinouColoredSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/teddy-and-minou-colored').then((mod) => mod.TeddyAndMinouColoredSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const XmasIconColored = dynamicHydrate(() => import('@/components/ui/atoms/icons/xmas-icon-colored'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const XmasIconColoredSvg = dynamicHydrate(
	() => import('@/components/ui/atoms/icons/xmas-icon-colored').then((mod) => mod.XmasIconColoredSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
